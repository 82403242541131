.etiqueta {
  background-color: #4CA3E4;
  /* background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjAwIDEyMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+PHBhdGggZD0iTTEyMDAsMEwwLDEyMEwxMjAwLDEyMFoiIGZpbGw9ImN1cnJlbnRDb2xvciI+PC9wYXRoPjwvc3ZnPg=='); */
  background-size: cover;
  padding: 20px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  color: #000;
  z-index: -1; /* Asegura que el fondo esté detrás de todos los componentes */
}

.etiqueta img.logo {
  margin-right: 20px; /* Espacio entre el logo y los datos */
}

.etiqueta img.logo {
  margin-right: 20px; /* Espacio entre el logo y los datos */
  width: 100px; /* Establece el ancho de la imagen */
}

.etiqueta p {
  margin: 0; /* Elimina los márgenes por defecto de los párrafos */
  font-size: 1.2em; /* Aumenta el tamaño de la fuente */
}
@media print {
  body * {
    visibility: hidden;
  }
  #etiquetaParaImprimir, #etiquetaParaImprimir * {
    visibility: visible;
  }
  #etiquetaParaImprimir {
    position: absolute;
    left: 0;
    top: 0;
    width: 105mm; /* Ancho de una hoja A4 */
    height: 50mm; /* La mitad del largo de una hoja A4 */
  }
}