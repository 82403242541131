.formulario-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Asegura que el contenedor tenga al menos la altura de la ventana */
}

form {
  max-width: 600px; /* Establece un ancho máximo para el formulario */
  width: 100%; /* Asegura que el formulario ocupe todo el ancho del contenedor hasta el máximo establecido */
  padding: 20px; /* Espaciado interno para que el contenido no toque los bordes */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Opcional: agrega una sombra para resaltar el formulario */
}

.centrado {
  text-align: center;
  background-color: navy; /* Fondo azul marino */
}

.row {
  display: flex;
  align-items: center; /* Alinea verticalmente los elementos del formulario */
  justify-content: space-between; /* Espacia los elementos uniformemente */
}

.button-container {
  display: flex;
  justify-content: center; /* Centra el botón horizontalmente */
  margin-top: 20px; /* Espacio adicional arriba del botón, ajusta según necesidad */
}

.radio-group-row {
  display: flex;
  flex-direction: row;
}

.custom-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.formulario-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Asegura que el contenedor tenga al menos la altura de la ventana */
}

.label-container {
  width: 50%; /* O ajusta según sea necesario */
  /* Puedes necesitar ajustar los estilos específicos para el contenedor de la etiqueta aquí */
}


.my-container {
  display: flex;
  justify-content: space-between; /* Espacia los elementos uniformemente a lo largo del eje principal */
  align-items: start; /* Alinea los elementos al inicio de su contenedor a lo largo del eje cruzado */
  padding: 20px; /* Añade un poco de espacio alrededor para evitar que los elementos toquen los bordes */
}